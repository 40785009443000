<template>
  <CCard>
    <CCardHeader>
      <slot name="header"> <i :class="icon"></i>&nbsp; {{ caption }} </slot>      
    </CCardHeader>
    <CCardBody>
      <CDataTableDev
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fieldsp="fieldsp"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
        :caption="vue.texto"
        @action_add="action_add"
      >
        <template #status="{ item }">
          <td>
            <CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
          </td>
        </template>
      </CDataTableDev>
    </CCardBody>
  </CCard>
</template>

<script>
import CDataTableDev from './cDatatableAssigPermission.vue'
export default {
  name: "Table",
  data(){
    return{
      vue: {
        texto: true
      }
    }
  },
  components:{
    CDataTableDev
  },
  props: {
    items: Array,
    fieldsp: {
      type: Array,
      default:[]
    },
    caption: {
      type: String,
      default: "Table",
    },
    icon: {
      type: String,
      default: "Table",
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
  },
  methods: {
    getBadge(status) {
      return status === "Active"
        ? "success"
        : status === "Inactive"
        ? "secondary"
        : status === "Pending"
        ? "warning"
        : status === "Banned"
        ? "danger"
        : "primary";
    },
    action_add: function(item){
      this.$emit('action_add',item);
    }
  },
};
</script>
